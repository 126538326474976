import InputMask from "react-input-mask";

export function ComponenteFidelidade({ Empresa, Cliente, SetDadosCliente }) {
  return (
    <>
      {Empresa.fidelidade_status == 1 ? (
        <>
          <div className="container-group mb-2">
            <span className="badge">Obrigatório</span>

            <p className="title-categoria mb-2">
              <b>Número do CPF</b>
            </p>
            <span className="sub-title-categoria">
              Utilizado para créditar seus pontos de fidelidade.
            </span>

            <InputMask
              mask="999.999.999-99"
              maskChar={null}
              placeholder="999.999.999-99"
              className="form-control mt-2"
              onChange={(e) => SetDadosCliente("cpf", e.target.value)}
              value={Cliente.cpf}
            >
              {(inputProps) => <input {...inputProps} />}
            </InputMask>
            <p>
              <span className="sub-title-categoria">
                Consulte o regulamento clicando aqui!
              </span>
            </p>
          </div>

          <div className="container-group mb-3">
            <span className="badge">Obrigatório</span>

            <p className="title-categoria mb-2">
              <b>Data de Nascimento</b>
            </p>

            <InputMask
              mask="99/99/9999"
              maskChar={null}
              placeholder="99/99/9999"
              className="form-control mt-2"
              onChange={(e) => SetDadosCliente("nascimento", e.target.value)}
              value={Cliente.nascimento}
            >
              {(inputProps) => <input {...inputProps} />}
            </InputMask>
          </div>
        </>
      ) : null}
    </>
  );
}
