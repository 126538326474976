import {
  MascaraMonetaria,
  Notificacao,
  PrimeiraLetraMaiuscula,
} from "../../../controller/function";
import { ShowModal } from "../../modal";
import { ComponenteFormaPagamento } from "./forma";
import NumberFormat, {
  NumberFormatBase,
  NumericFormat,
} from "react-number-format";

export function ComponentePagamentoCarrinho({
  CloseButton,
  Data,
  PagamentoSelecionado,
  setPagamentoSelecionado,
  Troco,
  setTroco,
  Total,
}) {
  function Close() {
    setPagamentoSelecionado({});
    CloseButton();
  }

  function SelecionarFormaPagamento() {
    if (!PagamentoSelecionado.troco) {
      Notificacao("Forma de pagamento selecionada!");
      CloseButton();
      return false;
    }
    if (Total < Troco) {
    } else {
      Notificacao(`Seu troco deve ser maior que ${MascaraMonetaria(Total)}.`);
      return false;
    }
    Notificacao("Forma de pagamento selecionada!");
    CloseButton();
  }

  function convertCurrencyToNumber(currency) {
    // Remove o prefixo "R$ " e espaços em branco
    let numericValue = currency.replace(/[R$\s]/g, "");

    // Substitui o separador de milhares "." por nada
    numericValue = numericValue.replace(/\./g, "");

    // Substitui a vírgula decimal "," por um ponto "."
    numericValue = numericValue.replace(/,/, ".");

    // Converte a string para um número de ponto flutuante
    return parseFloat(numericValue);
  }

  return (
    <>
      <ShowModal>
        <header className="width-fix mt-3">
          <div className="card">
            <div className="d-flex">
              <div className="container-voltar" onClick={Close}>
                <i className="fas fa-arrow-left"></i>
              </div>
              <div className="infos text-center">
                <h1 className="mb-0">
                  <b>Forma de Pagamento</b>
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div style={{ marginTop: "10px" }}>
          {Data.map((pagamento, index) => (
            <ComponenteFormaPagamento
              Descricao={pagamento.descricao}
              Pix={pagamento.mp_pix}
              index={index}
              setPagamentoSelecionado={setPagamentoSelecionado}
              PagamentoSelecionado={PagamentoSelecionado}
              key={index}
            />
          ))}

          {PagamentoSelecionado.troco ? (
            <>
              <div className="container-group mt-5 mb-3">
                <p className="title-categoria mb-0">
                  <b>Troco</b>
                </p>
                <span className="sub-title-categoria">
                  Informe o valor que você possue.
                </span>
                <NumericFormat
                  id="troco"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  placeholder="R$ 0,00"
                  className="form-control mt-2"
                  defaultValue={Troco > 0 ? Troco : null}
                  onBlur={(values) =>
                    setTroco(convertCurrencyToNumber(values.target.value))
                  }
                  // onValueChange={(values) => setTroco(values.floatValue)}
                  // value={Troco}
                  customInput={(inputProps) => <input {...inputProps} />}
                />
              </div>
            </>
          ) : null}
        </div>

        {PagamentoSelecionado.descricao != "" ? (
          <>
            {" "}
            <button
              id="closePagamento"
              onClick={SelecionarFormaPagamento}
              className="btn btn-yellow btn-full-size mt-2"
            >
              Selecionar
            </button>
          </>
        ) : null}
      </ShowModal>
    </>
  );
}
