export function ShowModal({ CloseButton, children }) {
  return (
    <>
      <div class="modal-overlay">
        <div class="modal-content">
          {CloseButton ? (
            <>
              {" "}
              <span class="modal-close" onClick={CloseButton}>
                &times;
              </span>
            </>
          ) : null}

          {children}
        </div>
      </div>
    </>
  );
}
