import {
  EncodeCodigo,
  MascaraMonetaria,
  Url,
  converterData,
} from "../../../../controller/function";
import { StatusPedido } from "../status";

export function CardPedido({ pedido }) {
  // console.log(pedido);
  function VisualizarPedido() {
    window.location.href = Url() + "/pedido/detalhe/" + EncodeCodigo(pedido.id);
  }
  return (
    <>
      <div class="card card-status-pedido mb-4">
        <div class="detalhes-produto">
          <div class="infos-produto">
            <p class="name-total mb-0">
              <b>{`#${pedido.id} - ${pedido.codigo_pedido}`}</b>
            </p>
            <p class="name-total mb-0">
              <b>{converterData(pedido.data)}</b>
            </p>
            <p class="name-total mb-0">
              <b>Total</b>
            </p>
            <p class="price-total mb-0">
              <b>{MascaraMonetaria(pedido.total)}</b>
            </p>
            <StatusPedido status={pedido.status} />
          </div>
        </div>
        <div class="detalhes-produto-acoes" onClick={VisualizarPedido}>
          <i class="far fa-file-alt"></i>
          <p class="mb-0 mt-1">Ver pedido</p>
        </div>
      </div>
    </>
  );
}
