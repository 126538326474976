import { ComponentAdicionalProduto } from "../../../componentes/produto/adicional";

import { PrimeiraLetraMaiuscula } from "../../../controller/function";

export function Adicional({
  Adicionais,
  AdicionaisSelecionados,
  AddAdicional,
  produto,
}) {
  return (
    <>
      {Adicionais.map((adicional, index) => (
        <>
          <div className="container-group mb-5" key={index}>
            {adicional.minimo == adicional.amount ? (
              <span className="badge">Obrigatório</span>
            ) : null}
            <p className="title-categoria mb-0">
              <b>{PrimeiraLetraMaiuscula(adicional.name_adicionais_cat)}</b>
            </p>
            {adicional.minimo == adicional.amount ? (
              <span className="sub-title-categoria">
                Escolha {adicional.amount}{" "}
                {PrimeiraLetraMaiuscula(adicional.name_adicionais_cat)}
              </span>
            ) : (
              <span className="sub-title-categoria">
                Escolha até {adicional.amount}{" "}
                {PrimeiraLetraMaiuscula(adicional.name_adicionais_cat)}
              </span>
            )}

            <ComponentAdicionalProduto
              produto={produto}
              codigo={adicional.id}
              min={adicional.minimo}
              max={adicional.amount}
              selecionado={AdicionaisSelecionados}
              add={AddAdicional}
              nome={adicional.name_adicionais_cat}
            />
          </div>
        </>
      ))}
    </>
  );
}
