import { useState, useEffect } from "react";
import { ComponenteEmpresa } from "../../componentes/empresa";
import {
  API,
  APIGOOPEDIR,
  PrimeiraLetraMaiuscula,
  RetornaIconePagamento,
} from "../../controller/function";
import Mapa from "../../componentes/Mapa/Mapa";
import { ComponentLoading } from "../../componentes/loading";

export function PageSobre() {
  const [Empresa, setEmpresa] = useState([]);
  const [Categoria, setCategoria] = useState([]);

  const [FormaPagamento, setFormaPagamento] = useState([]);

  useEffect(() => {
    if (Empresa.user_id != undefined) {
      // API.get(
      //   "generica.php?tabela=ws_formas_pagamento&where=user_id = " +
      //     Empresa.user_id
      // ).then(function (response) {
      //   let data = response.data;
      //   setFormaPagamento(data);
      // });

      APIGOOPEDIR.get(`api/forma/pagamento/${Empresa.user_id}`).then(function (
        response
      ) {
        let data = response.data;
        setFormaPagamento(data);
      });
    }
  }, [Empresa]);

  return (
    <>
      <ComponenteEmpresa
        setempresa={setEmpresa}
        setcategoria={setCategoria}
        display={"none"}
      />

      {Empresa.length !== 0 ? (
        <>
          <div className="bg-top sobre"></div>

          <header className="width-fix mt-3">
            <div className="card">
              <div className="d-flex">
                <a href="./" className="container-voltar">
                  <i className="fas fa-arrow-left"></i>
                </a>
                <div className="infos text-center">
                  <h1 className="mb-0">
                    <b>Sobre a loja</b>
                  </h1>
                </div>
              </div>
            </div>
          </header>

          <section className="width-fix mt-5 mb-4">
            <div className="card">
              <div className="d-flex">
                <div
                  className="container-img-sobre"
                  style={{
                    backgroundImage: `url("https://fotos.goopedir.com/fotos/empresa/${btoa(
                      Empresa.user_id
                    )}")`,
                    backgroundSize: "70%",
                  }}
                ></div>

                <div className="infos">
                  <h1 className="title-sobre">
                    <b>{PrimeiraLetraMaiuscula(Empresa.nome_empresa)}</b>
                  </h1>
                  <div className="infos-sub">
                    <p className="sobre mb-2">
                      {PrimeiraLetraMaiuscula(Empresa.descricao_empresa)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="lista width-fix mt-5 pb-5">
            <div className="container-group mb-5">
              <p className="title-categoria mb-0">
                <i className="fas fa-map-marker-alt"></i>&nbsp; <b>Endereço</b>
              </p>
              <div className="card mt-2">
                <p className="normal-text mb-0">
                  {PrimeiraLetraMaiuscula(Empresa.end_rua_n_empresa)},{" "}
                  {PrimeiraLetraMaiuscula(Empresa.end_bairro_empresa)},{" "}
                  {PrimeiraLetraMaiuscula(Empresa.cidade_empresa)}/
                  {Empresa.end_uf_empresa}
                </p>
                {Empresa ? (
                  <>
                    <div style={{ marginTop: "10px" }}>
                      <Mapa
                        Latitude={Empresa.lat ? Empresa.lat : 0}
                        Longitude={Empresa.lgn ? Empresa.lgn : 0}
                        Empresa={PrimeiraLetraMaiuscula(Empresa?.nome_empresa)}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <div className="container-group mb-5">
              <p className="title-categoria mb-0">
                <i className="fas fa-clock"></i>&nbsp;{" "}
                <b>Horário de funcionamento</b>
              </p>

              {Empresa.config_segunda == "true" ? (
                <>
                  <div className="card mt-2">
                    <p className="normal-text mb-0">
                      <b>Segunda</b>
                    </p>
                    <p className="normal-text mb-0">
                      {Empresa.segunda_manha_de} às {Empresa.segunda_manha_ate}
                    </p>
                  </div>
                </>
              ) : null}

              {Empresa.config_terca == "true" ? (
                <>
                  <div className="card mt-2">
                    <p className="normal-text mb-0">
                      <b>Terça</b>
                    </p>
                    <p className="normal-text mb-0">
                      {Empresa.terca_manha_de} às {Empresa.terca_manha_ate}
                    </p>
                  </div>
                </>
              ) : null}

              {Empresa.config_quarta == "true" ? (
                <>
                  <div className="card mt-2">
                    <p className="normal-text mb-0">
                      <b>Quarta</b>
                    </p>
                    <p className="normal-text mb-0">
                      {Empresa.quarta_manha_de} às {Empresa.quarta_manha_ate}
                    </p>
                  </div>
                </>
              ) : null}

              {Empresa.config_quinta == "true" ? (
                <>
                  <div className="card mt-2">
                    <p className="normal-text mb-0">
                      <b>Quinta</b>
                    </p>
                    <p className="normal-text mb-0">
                      {Empresa.quinta_manha_de} às {Empresa.quinta_manha_ate}
                    </p>
                  </div>
                </>
              ) : null}

              {Empresa.config_sexta == "true" ? (
                <>
                  <div className="card mt-2">
                    <p className="normal-text mb-0">
                      <b>Sexta</b>
                    </p>
                    <p className="normal-text mb-0">
                      {Empresa.sexta_manha_de} às {Empresa.sexta_manha_ate}
                    </p>
                  </div>
                </>
              ) : null}

              {Empresa.config_sabado == "true" ? (
                <>
                  <div className="card mt-2">
                    <p className="normal-text mb-0">
                      <b>Sábado</b>
                    </p>
                    <p className="normal-text mb-0">
                      {Empresa.sabado_manha_de} às {Empresa.sabado_manha_ate}
                    </p>
                  </div>
                </>
              ) : null}

              {Empresa.config_domingo == "true" ? (
                <>
                  <div className="card mt-2">
                    <p className="normal-text mb-0">
                      <b>Domingo</b>
                    </p>
                    <p className="normal-text mb-0">
                      {Empresa.domingo_manha_de} às {Empresa.domingo_manha_ate}
                    </p>
                  </div>
                </>
              ) : null}
            </div>

            <div className="container-group mb-5">
              <p className="title-categoria mb-0">
                <i className="fas fa-coins"></i>&nbsp;{" "}
                <b>Formas de pagamento</b>
              </p>

              {FormaPagamento.map((pagamento, index) => (
                <>
                  <div className="card card-address mt-2">
                    <div className="img-icon-details">
                      {pagamento.descricao
                        ? RetornaIconePagamento(pagamento.descricao)
                        : null}
                    </div>
                    <div className="infos">
                      <p className="name mb-0">
                        <b>{PrimeiraLetraMaiuscula(pagamento.descricao)}</b>
                      </p>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </section>

          <a href="./" className="btn btn-yellow btn-full voltar">
            Voltar para o cardápio
          </a>

          <section
            className="menu-bottom disabled hidden"
            id="menu-bottom-closed"
          >
            <p className="mb-0">
              <b>Loja fechada no momento.</b>
            </p>
          </section>
        </>
      ) : (
        <ComponentLoading />
      )}
    </>
  );
}
