import {
  PrimeiraLetraMaiuscula,
  converterData,
} from "../../../../controller/function";
import { PixIcon } from "./pix";

export function iconePorName(nome) {
  if (PrimeiraLetraMaiuscula(nome) == "Novo Pedido") {
    return <i class="fas fa-clock"></i>;
  }

  if (PrimeiraLetraMaiuscula(nome) == "Em Produção") {
    return <i class="fas fa-utensils"></i>;
  }

  if (PrimeiraLetraMaiuscula(nome) == "Disponível Para Retirada") {
    return <i class="fas fa-box"></i>;
  }

  if (PrimeiraLetraMaiuscula(nome) == "Saiu Para Entrega") {
    return <i class="fas fa-motorcycle"></i>;
  }

  if (PrimeiraLetraMaiuscula(nome) == "Finalizado") {
    return <i class="fas fa-check"></i>;
  }

  if (PrimeiraLetraMaiuscula(nome) == "Pronto") {
    return <i class="fas fa-check"></i>;
  }

  if (PrimeiraLetraMaiuscula(nome) == "Cancelado") {
    return <i class="fas fa-times"></i>;
  }

  return <PixIcon />;
}

export function DetalheStatus({ detalhe, ativo }) {
  function Descricao(nome) {
    if (PrimeiraLetraMaiuscula(nome) == "Novo Pedido") {
      return "Seu pedido ainda não foi aceito.";
    }

    if (PrimeiraLetraMaiuscula(nome) == "Cancelado") {
      return "O restaurante cancelou o seu pedido. Entre em contato para mais informações.";
    }

    if (PrimeiraLetraMaiuscula(nome) == "Em Produção") {
      return "Seu pedido já esta na cozinha.";
    }

    if (PrimeiraLetraMaiuscula(nome) == "Disponível Para Retirada") {
      return "Seu pedido já esta disponível para retirada.";
    }

    if (PrimeiraLetraMaiuscula(nome) == "Saiu Para Entrega") {
      return "O motoboy já saiu para entrega.";
    }

    if (PrimeiraLetraMaiuscula(nome) == "Finalizado") {
      return "Seu pedido foi finalizado.";
    }

    return "";
  }

  function Cancelado(nome) {
    return PrimeiraLetraMaiuscula(nome) == "Cancelado";
  }

  return (
    <>
      <div
        class={`card card-status-pedido mt-2 ${
          ativo
            ? Cancelado(detalhe.status)
              ? "cancelado"
              : "active"
            : "pending"
        }`}
      >
        <div class="img-icon-details">
          {/* <i class="fas fa-utensils"></i> */}
          {iconePorName(detalhe.status)}
        </div>
        <div class="infos">
          <p class="name mb-0">
            <b>{PrimeiraLetraMaiuscula(detalhe.status)}</b>
          </p>
          {ativo ? (
            <p className="price mb-0">{Descricao(detalhe.status)}</p>
          ) : null}
          <p className="price mb-0">
            <b>{converterData(detalhe.datahora)}</b>
          </p>
        </div>
      </div>
    </>
  );
}
