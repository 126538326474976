import Skeleton from "react-loading-skeleton";
import { ComponentButton } from "../../componentes/buttomBottom";
import { ComponenteEmpresa } from "../../componentes/empresa";
import { ComponenteProdutoPrincipal } from "../../componentes/produtoPrincipal";
import { PrimeiraLetraMaiuscula, Url } from "../../controller/function";
import React, { useState, useRef, useEffect } from "react";
import { ComponentLoading } from "../../componentes/loading";

export function PagePrincipal() {
  const [Empresa, setEmpresa] = useState([]);
  const [Categoria, setCategoria] = useState([]);
  const [CategoriaSelecionada, setCategoriaSelecionada] = useState(0);
  const [secaoAtual, setSecaoAtual] = useState(null);

  const refs = useRef([]);

  useEffect(() => {
    if (
      CategoriaSelecionada !== null &&
      refs.current[CategoriaSelecionada] &&
      refs.current[CategoriaSelecionada].current
    ) {
      refs.current[CategoriaSelecionada].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [CategoriaSelecionada]);

  const handleScroll = () => {
    let indexAtual = null;

    Categoria.forEach((_, index) => {
      const refCurrent = refs.current[index].current;
      if (refCurrent) {
        const top = refCurrent.getBoundingClientRect().top;
        if (
          top <= window.innerHeight * 0.2 &&
          top >= -window.innerHeight * 0.8
        ) {
          indexAtual = index;
        }
      }
    });

    if (indexAtual !== null && indexAtual !== secaoAtual) {
      setSecaoAtual(indexAtual);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [Categoria, secaoAtual]);

  return (
    <>
      <ComponenteEmpresa
        setempresa={setEmpresa}
        setcategoria={setCategoria}
        display={""}
      />
      {Empresa.length !== 0 ? (
        <>
          <section className="categoria width-fix mt-4">
            <div className="container-menu" id="listaCategorias">
              {Categoria.map((categoria, index) => (
                <p
                  key={index}
                  className={
                    CategoriaSelecionada == index
                      ? "item-categoria btn btn-white-select btn-sm mb-3 me-3"
                      : "item-categoria btn btn-white btn-sm mb-3 me-3"
                  }
                  onClick={() => setCategoriaSelecionada(index)}
                >
                  {PrimeiraLetraMaiuscula(categoria.nome_cat)}
                </p>
              ))}
            </div>
          </section>

          <section
            className="lista width-fix mt-0 pb-5"
            id="listaItensCardapio"
          >
            {Categoria.forEach((_, i) => {
              if (!refs.current[i]) refs.current[i] = React.createRef();
            })}
            {Categoria.map((categoria, index) => (
              <div
                className="container-group mb-5"
                key={index}
                ref={refs.current[index]}
              >
                <p className="title-categoria">
                  <b>{PrimeiraLetraMaiuscula(categoria.nome_cat)}</b>
                </p>
                <ComponenteProdutoPrincipal
                  categoria={categoria.id}
                  empresa={Empresa}
                  total={categoria.ws_total}
                />
              </div>
            ))}
          </section>

          <ComponentButton />
        </>
      ) : (
        <>
          <ComponentLoading />
        </>
      )}
    </>
  );
}
