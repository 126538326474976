import { useState } from "react";
import { ComponenteEmpresa } from "../../componentes/empresa";
import { Url } from "../../controller/function";

export function PageFideldiade() {
  const [Empresa, setEmpresa] = useState([]);
  const [Categoria, setCategoria] = useState([]);

  return (
    <>
      <ComponenteEmpresa
        setempresa={setEmpresa}
        setcategoria={setCategoria}
        display={"none"}
      />

      {Empresa.length !== 0 ? (
        <>
          <div className="bg-top sobre"></div>

          <header className="width-fix mt-3">
            <div className="card">
              <div className="d-flex">
                <a href={Url()} className="container-voltar">
                  <i className="fas fa-arrow-left"></i>
                </a>
                <div className="infos text-center">
                  <h1 className="mb-0">
                    <b>Programa de Fidelidade</b>
                  </h1>
                </div>
              </div>
            </div>
          </header>

          <header className="width-fix mt-2">
            <div className="card">
              <div className="d-flex">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  {" "}
                  <div
                    className="container-img"
                    style={{
                      backgroundImage: `url("/gift.png")`,
                    }}
                  ></div>
                </div>
                <div className="infos">
                  <h1 style={{ fontSize: "0.85rem" }}>
                    Seus Pontos: <b>100</b>
                  </h1>
                  {/* <p style={{ fontSize: "0.75rem" }}>
                    A cada R$ 1,00 em compras você ganha 1 ponto que pode ser
                    trocado por prêmios e descontos exclusivos.
                  </p> */}
                  <a
                    href={Url() + "/fidelidade"}
                    className="link"
                    style={{ fontSize: "0.75rem" }}
                  >
                    Clique aqui e leia o regulamento
                  </a>

                  {/* <div className="infos-sub">
                <p className="status-open">
                  <i className="fa fa-clock"></i> Aberta
                </p>
                <a href={Url() + "/sobre"} className="link">
                  ver mais
                </a>
              </div> */}
                </div>
              </div>
            </div>
          </header>
          {/* <header className="width-fix mt-2">
            <div className="card">
              <div className="d-flex">
                <h1>Resgates</h1>
                <h1>Recebimento</h1>
              </div>
            </div>
          </header> */}
        </>
      ) : null}
    </>
  );
}
