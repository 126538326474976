export function PagePrivacidade() {
  return (
    <>
      <p>
        Mediante este Termo de Uso do Cliente, a empresa GOOPEDIR LTDA,
        registrada sob o CNPJ 43.732.082/0001-71, localizada na RUA JOÃO
        SERAFIM, nº 90, CRICIÚMA, SC, CEP 88.806-120, estabelece sua Política de
        Privacidade, reafirmando seu compromisso com a segurança dos dados de
        seus clientes e parceiros no uso do aplicativo e site Goopedir.
      </p>
      <br />
      <h1>
        <b>I. ENTENDIMENTOS E DIRETRIZES GERAIS</b>
      </h1>
      <p>
        <b>Plataforma:</b> O Aplicativo Goopedir permite o tratamento de
        informações através de software.
      </p>
      <p>
        <b>Site:</b> O endereço online da Goopedir está registrado em
        goopedir.com/goopedir.com.br.
      </p>
      <p>
        <b>Cliente:</b> Indivíduos cadastrados na Goopedir, capazes civilmente,
        que concordam com os termos e política de privacidade disponível nas
        plataformas da empresa.
      </p>
      <p>
        <b>Parceiros:</b> Estabelecimentos com identidade jurídica que optam
        pelos serviços da Goopedir.
      </p>
      <p>
        <b>Programa de Fidelidade:</b> É uma iniciativa dos parceiros para
        premiar seus clientes frequentes. A Goopedir apenas gerencia as
        informações para a execução desse programa, sem se responsabilizar por
        eventuais problemas ou mudanças nos critérios de fidelidade.
      </p>
      <p>
        <b>Registro:</b> Para acessar certos recursos da Goopedir, é obrigatório
        o cadastro, que exigirá dados pessoais do usuário, garantindo a
        veracidade das informações e permitindo o uso pela empresa.
      </p>
      <p>
        <b>Segurança do Usuário:</b> A confidencialidade das credenciais é de
        responsabilidade do usuário, que deve notificar a Goopedir sobre
        qualquer atividade suspeita em sua conta.
      </p>
      <p>
        <b>Dados Pessoais:</b> Informações que identifiquem uma pessoa, como
        nome, CPF, endereço, etc.
      </p>
      <p>
        <b>Manipulação de Dados:</b> Processos envolvendo a coleta,
        armazenamento e eliminação de dados, conforme a política da Goopedir.
      </p>
      <p>
        <b>Mudanças nos Serviços:</b> A Goopedir pode modificar ou descontinuar
        seus serviços a qualquer momento. Qualquer perda resultante dessas
        alterações é assumida pelo usuário.
      </p>
      <p>
        <b>Recompensas:</b> Promoções oferecidas exclusivamente pelos parceiros,
        sem interferência da Goopedir.
      </p>
      <p>
        <b>Acesso através de Terceiros:</b> O cadastro pode ser realizado via
        plataformas de terceiros, como Facebook ou Google. A Goopedir não se
        responsabiliza pela forma como esses terceiros tratam as informações.
      </p>
      <p>
        <b>Acesso Móvel:</b> Serviços da Goopedir podem ser acessados via
        dispositivos móveis, sujeitos a tarifas e restrições de operadoras.
      </p>
      <p>
        <b>Política de Privacidade:</b> Diretrizes relativas ao tratamento de
        dados dos usuários, disponível em:
        empresas.Goopedir.app/termos-e-politicas.
      </p>
      <br />
      <h1>
        <b>‍II. TERMOS DE UTILIZAÇÃO</b>
      </h1>
      <p>
        Comportamento do Usuário: O Usuário compreende que é o único responsável
        por todo conteúdo, seja ele texto, imagem, vídeo, música ou qualquer
        outro tipo de informação que publique ou compartilhe através do serviço.
      </p>
      <p>
        Afirmação de Propriedade: Ao aceitar este acordo, o Usuário assegura ser
        o verdadeiro proprietário de todos os dados e informações fornecidos
        durante o cadastro e uso do serviço, excluindo o uso de informações de
        terceiros.
      </p>
      <p>
        Finalidade Não-Comercial: A menos que especificamente permitido por este
        acordo, o Usuário compromete-se a não utilizar o serviço para fins
        comerciais. O serviço é destinado apenas para uso individual.
      </p>
      <p>
        Permissões de Uso de Dados: O Usuário, ao aceitar estes termos, permite
        que a Goopedir utilize suas informações pessoais por tempo indeterminado
        para:
      </p>
      <p>(i) realizar pesquisas demográficas</p>
      <p>(ii) entender melhor as necessidades dos usuários</p>
      <p>(iii) otimizar a promoção e o conteúdo oferecido</p>
      <p>(iv) enviar comunicações personalizadas.</p>
      <p>(v) usar o serviço de localização do usuário.</p>
      <p>
        O Usuário concorda que a Goopedir pode usar suas informações, de forma
        anônima ou identificada, para ações de marketing.
      </p>
      <p>
        Não serão usados dados extremamente pessoais do Usuário para estudos que
        apontem comportamentos individuais. Contudo, dados agregados podem ser
        usados para identificar tendências gerais.
      </p>
      <p>
        Ao se inscrever no serviço, o Usuário consente que, ao visitar
        estabelecimentos parceiros da Goopedir, seu registro seja feito
        automaticamente nesses locais.
      </p>
      <p>
        Se desejar, o Usuário pode cancelar seu cadastro em qualquer
        estabelecimento parceiro através das configurações de perfil no site.
      </p>
      <br />
      <h1>
        <b>III. DIRETRIZES DE INSCRIÇÃO</b>
      </h1>
      <p>
        Para usar os Serviços, é crucial fornecer dados precisos durante o
        processo de inscrição. O Usuário é integralmente responsável pela
        veracidade e correção das informações apresentadas, que a Goopedir pode
        verificar a qualquer momento.
      </p>
      <p>
        Se informações falsas ou não confirmadas forem identificadas, ou se o
        Usuário se recusar a retificar ou comprovar as informações mediante
        documentos, a Goopedir tem direito de interromper o processo de cadastro
        ou bloquear um perfil já ativado, até que as inconsistências sejam
        resolvidas.
      </p>
      <p>
        Em casos de irregularidades ou uso abusivo detectado, a Goopedir pode
        optar por barrar, suspender ou encerrar um perfil, especialmente se
        houver indícios de tentativas de violação dos Termos de Uso.
      </p>
      <p>
        Após a conclusão do cadastro, o Usuário terá acesso aos serviços usando
        um login e senha. O Usuário reconhece que deve manter essas credenciais
        confidenciais. Qualquer atividade realizada com essas credenciais será
        de responsabilidade exclusiva do Usuário.
      </p>
      <p>
        Para se inscrever e usar os Serviços, o Usuário deve ser legalmente apto
        a firmar contratos. Se o Usuário for menor de 18 anos, é essencial ter o
        consentimento e supervisão de um adulto ou tutor legal.
      </p>
      <p>
        Ao adquirir produtos, serviços ou interagindo com outros usuários, o
        Usuário confirma sua capacidade legal ou, se for menor de idade, que
        obteve a devida permissão de seus responsáveis.
      </p>
      <p>
        Completar a inscrição não garante acesso completo e irrestrito aos
        Serviços no aplicativo.
      </p>
      <br />
      <h1>
        <b>IV. RESPONSABILIDADES DO USUÁRIO</b>
      </h1>
      <p>
        O Usuário deve garantir que todas as informações fornecidas durante o
        cadastro sejam autênticas, exatas e atualizadas, respondendo civil e
        criminalmente por qualquer inveracidade ou imprecisão. É crucial manter
        os dados sempre atualizados.
      </p>
      <p>
        A responsabilidade de proteger o acesso ao aplicativo, através do login
        e senha, é exclusiva do Usuário. O compartilhamento destas informações
        com terceiros é proibido, e o Usuário será responsabilizado por
        quaisquer ações tomadas com seu acesso.
      </p>
      <p>
        O Usuário deve assegurar sua própria conexão à internet para acessar os
        Serviços.
      </p>
      <p>
        É esperado que o Usuário tenha mais de 18 anos para se registrar e usar
        o Aplicativo. Caso contrário, é essencial obter o consentimento expresso
        de pais ou tutores legais.
      </p>
      <p>
        Ao utilizar os serviços, o Usuário aceita a eventual exposição a
        campanhas de marketing e promoções da Goopedir, parceiros e
        comerciantes.
      </p>
      <p>
        O Usuário deve respeitar todos os direitos da Goopedir e de terceiros,
        incluindo, mas não se limitando a, direitos autorais e propriedade
        intelectual.
      </p>
      <p>
        Ao concordar com este contrato, o Usuário também concorda com a Política
        de Privacidade da Goopedir, que complementa este documento.
      </p>
      <p>
        O Usuário será pessoalmente responsável por quaisquer danos ou perdas
        causados, isentando a Goopedir de qualquer responsabilidade.
      </p>
      <p>
        O reconhecimento da propriedade da marca da Goopedir é essencial, e o
        Usuário deve evitar qualquer ação que viole os direitos da empresa sobre
        o Aplicativo ou marca.
      </p>
      <p>
        O Usuário cede à Goopedir quaisquer direitos sobre melhorias ou
        adaptações feitas no Aplicativo, Website ou Serviços, aceitando que
        estas se tornem parte integral do produto sem qualquer compensação.
      </p>
      <p>
        Mesmo se o Usuário contribuir com sugestões ou feedback para melhorias,
        ele concorda que a Goopedir detém todos os direitos sobre tais
        contribuições, sem a necessidade de compensação ao Usuário.
      </p>
      <br />
      <h1>
        <b>V. SOBRE DIREITOS INTELECTUAIS</b>
      </h1>
      <p>
        Conteúdo, Programação e Identidade Visual: O Usuário reconhece que o
        Serviço, assim como o Website e o Aplicativo, podem conter materiais
        protegidos por direitos autorais, propriedade intelectual, marcas,
        patentes e outras proteções legais. É proibido ao Usuário alterar,
        copiar, redistribuir, licenciar ou explorar tais conteúdos de qualquer
        maneira que não esteja explicitamente permitida aqui. Não é permitido ao
        Usuário utilizar métodos automáticos, como robôs, para coletar
        informações do serviço ou conteúdo. Todo software e tecnologia
        associados são propriedade da Goopedir ou de seus parceiros.
      </p>
      <p>
        Identidade da Goopedir: A Goopedir possui direitos exclusivos sobre seu
        nome, logotipo e outros elementos identificadores. Qualquer uso de
        marcas, seja da Goopedir ou de terceiros, sem a devida permissão, é
        proibido.
      </p>
      <p>
        Conteúdo de Outros: A Goopedir não se responsabiliza pelo conteúdo
        fornecido por terceiros, incluindo recompensas, informações ou outros
        materiais. O Usuário reconhece que a Goopedir não revisa previamente
        esse tipo de conteúdo, embora reserve o direito de recusar ou remover
        qualquer conteúdo.
      </p>
      <p>
        Contribuições do Usuário: O conteúdo fornecido pelo Usuário é de sua
        única responsabilidade. Ao enviar informações ao serviço, o Usuário
        concede à Goopedir uma licença gratuita e global para usar, reproduzir,
        modificar e adaptar tal conteúdo. Tudo o que o Usuário enviar à
        Goopedir, incluindo feedback e sugestões, será tratado como não
        confidencial.
      </p>
      <p>
        Denúncias de Violação: Caso o Usuário acredite que seus direitos foram
        violados, ele pode enviar uma notificação à Goopedir para tomar as
        medidas cabíveis.
      </p>
      <p>
        Links Externos: O serviço pode conter links para outros sites. A
        Goopedir não é responsável pelo conteúdo ou políticas desses sites
        externos. Qualquer interação do usuário com esses sites é de sua
        responsabilidade.
      </p>
      <p>
        O respeito à propriedade intelectual é fundamental para a Goopedir, e
        espera-se que o Usuário tenha a mesma conduta ao usar o serviço.
      </p>
      <br />
      <h1>
        <b>VI. SOBRE REPARAÇÕES</b>
      </h1>
      <p>
        O Usuário compreende que não tem direito a reparações de qualquer
        natureza da Goopedir e seus associados por possíveis prejuízos
        relacionados ao uso do Serviço, Website e/ou Aplicativo. A Goopedir não
        é responsável por quaisquer perdas ou danos que o usuário possa
        experimentar, seja por falhas, interrupções ou outras situações
        derivadas do uso do serviço. Se o usuário não estiver satisfeito, deve
        parar de usar o serviço imediatamente.
      </p>
      <br />
      <h1>
        <b>VII. GARANTIAS E RESPONSABILIDADES</b>
      </h1>
      <p>
        O Usuário entende que o serviço é fornecido "como está", sem garantias
        claras ou subentendidas. O uso é de total responsabilidade do usuário. A
        Goopedir se exime de quaisquer garantias sobre o serviço, incluindo seu
        funcionamento e aplicabilidade. Não há garantias de que o serviço será
        ininterrupto ou livre de erros.
      </p>
      <p>
        A Goopedir cumpre todas as leis relacionadas à proteção de dados e segue
        padrões rigorosos. Entretanto, não é responsável pelo tratamento de
        dados feito exclusivamente por outras partes, como os comerciantes.
      </p>
      <p>
        Ambas as partes se comprometem a assumir responsabilidade em caso de
        tratamento indevido de dados pessoais, resguardando o direito da outra
        parte em caso de ações judiciais que possam surgir em relação ao uso do
        serviço, site ou aplicativo.
      </p>
      <br />
      <h1>
        <b>VIII. ENCERRAMENTO E INTERRUPÇÃO</b>
      </h1>
      <p>
        O Usuário entende que a Goopedir pode, a seu critério, interromper ou
        cancelar o acesso do usuário ao serviço, site e aplicativo por qualquer
        razão, incluindo, mas não limitado a, inatividade ou suspeitas de
        comportamento inadequado. A Goopedir pode optar por cessar suas
        operações ou limitar o acesso ao serviço sem aviso prévio. Qualquer
        informação associada à conta do usuário pode ser removida a critério da
        Goopedir. A Goopedir não assume responsabilidade por qualquer
        interrupção ou cancelamento de acesso.
      </p>
      <br />
      <h1>
        <b>IX. CONFLITOS ENTRE PARTICIPANTES</b>
      </h1>
      <p>
        O Usuário é responsável por suas interações com outros participantes do
        serviço. A Goopedir não se responsabiliza nem se envolve em disputas
        entre os usuários. Embora possa intervir em conflitos, não é obrigada a
        fazê-lo. A Goopedir não é responsável pelas ações de seus usuários ou
        comerciantes.
      </p>
      <br />
      <h1>
        <b>X. TERMOS FINAIS</b>
      </h1>
      <p>
        Este Acordo representa a totalidade do entendimento entre o Usuário e a
        Goopedir, suplantando quaisquer acordos anteriores referentes ao
        assunto. A inação da Goopedir em relação a algum direito não implica
        renúncia ao mesmo. Caso alguma parte deste Acordo seja considerada
        inválida, o restante permanece válido. O Usuário concorda em não iniciar
        processos contra a Goopedir relacionados ao Serviço após um ano do fato
        que gerou a reclamação. Impressões deste Acordo têm o mesmo valor que o
        documento digital original, e os títulos das seções são apenas
        referenciais.
      </p>
      <p>
        Não há nenhuma parceria, relação empregatícia ou similar entre as partes
        devido a este Acordo. A Goopedir pode alterar este Acordo quando quiser.
        Se alterado, os usuários serão notificados. Se o usuário discordar, deve
        parar de usar o serviço e cancelar sua conta.
      </p>
      <p>
        Quaisquer termos adicionais relacionados ao Serviço também se aplicam,
        incluindo a Política de Privacidade da Goopedir. O Usuário aceita que
        suas informações sejam coletadas e compartilhadas com parceiros,
        conforme acordado. Se o usuário usar o serviço em locais de parceiros,
        também deve considerar a política de privacidade destes parceiros.
      </p>
      <p>
        O usuário pode solicitar à Goopedir informações sobre seus dados, como
        confirmação de uso, correções, exclusões e outros, de acordo com a
        legislação vigente. Contatos podem ser feitos pelo endereço físico ou
        e-mail fornecidos.
      </p>
      <p>
        Ao usar o Serviço, o usuário confirma que compreende e concorda com
        todos os termos deste Acordo e da Política de Privacidade da Goopedir. O
        aceite é eletrônico e válido.
      </p>
      <p>
        Por fim, a Goopedir pode transferir seus direitos deste Acordo, parcial
        ou integralmente, algo que o usuário aceita desde já.
      </p>
      <br />
      <h1>
        <b>XI. POLÍTICA DE PRIVACIDADE</b>
      </h1>
      ‍
      <p>
        A abordagem da Goopedir sobre privacidade é detalhadamente descrita em
        sua Política de Privacidade (visível em:
        pedir.goopedir.com/termos-e-politicas). Ao interagir com o Serviço,
        Website ou Aplicativo e ao aceitar estes Termos, o Usuário concorda e
        reconhece como a Goopedir processa e gerencia suas informações pessoais,
        tudo conforme a Lei nº 13.709/2018.
      </p>
      <br />
      <h1>
        <b>XII. JURISDIÇÃO E NORMAS VIGENTES</b>
      </h1>
      ‍
      <p>
        O presente Termo é regido de acordo com as leis brasileiras. Qualquer
        disputa ou controvérsia entre as partes será resolvida no Foro Central
        da Comarca de Criciúma/SC, desconsiderando qualquer outro foro, por mais
        conveniente que possa ser.
      </p>
      <br />
      <h1>
        <b>XIII. CONTATO E FEEDBACK</b>
      </h1>
      ‍
      <p>
        Para esclarecer dúvidas, reportar problemas ou oferecer sugestões sobre
        este Termo de Uso, a Goopedir está disponível através do e-mail:
        contato@goopedir.com.
      </p>
    </>
  );
}
