import { PrimeiraLetraMaiuscula } from "../../../../controller/function";
import { iconePorName } from "../../../detalhe/componente/detalheStatus";

export function StatusPedido({ status }) {
  return (
    <>
      <div>
        <div
          class="card-status-pedido mt-2 "
          style={{ padding: "0px", fontSize: "14px", height: "30px" }}
        >
          <div
            class="img-icon-details"
            style={{ height: "30px", width: "30px" }}
          >
            <div style={{ fontSize: "14px" }}>{iconePorName(status)}</div>

            {/* <i class="fas fa-utensils" style={{ fontSize: "14px" }}></i> */}
          </div>
          <div class="infos">
            <p class="name-total mb-0">
              <b>{PrimeiraLetraMaiuscula(status)}</b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
