import { useState } from "react"
import { APIPAINEL, Busca, Grava } from "../../../controller/function"
import { PainelInicio } from "../inicio"
import { PainelLogin } from "../login"

export function PainelPrincipal() {

    const [Logado, setLogin] = useState(Login());

    function Login() {
        let token = Busca('painel_token')
        let user = Busca('painel_user')
        if (token == undefined) {
            return false
        }
        APIPAINEL.get('/empresa').then(function (response) {
            Grava('painel_empresa', JSON.stringify(response.data[0]));
        });

        return true
    }

    return (<>

        {Logado ? <><PainelInicio /></> : <><PainelLogin /></>}


    </>)
}