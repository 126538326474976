import {
  FormatoBR,
  PrimeiraLetraMaiuscula,
} from "../../../../controller/function";

export function ProdutoCard({ produto }) {
  return (
    <>
      <div className="card mt-2 pr-0">
        <div className="container-detalhes">
          <div className="detalhes-produto">
            <div className="infos-produto">
              <p className="name">
                <b>
                  {produto.qtd}x {PrimeiraLetraMaiuscula(produto.nome)}
                </b>
              </p>
              {1 > 0 ? (
                <p className="price">
                  <b>{FormatoBR(produto.valor)}</b>
                </p>
              ) : null}
            </div>

            {produto?.extra.map((sabores, index) => (
              <>
                <div className="infos-produto" key={"adc" + index}>
                  <p className="name-opcional mb-0">
                    {`${sabores.quantidade}x ${PrimeiraLetraMaiuscula(
                      sabores.categoria
                    )} ${PrimeiraLetraMaiuscula(sabores.descricao)}`}
                  </p>
                  {sabores.valor > 0 ? (
                    <p className="price-opcional mb-0">
                      + {FormatoBR(sabores.valor)}
                    </p>
                  ) : null}
                </div>
              </>
            ))}
            {/* 
            {product.adicional.map((adicional, index) => (
              <>
                <Adicionais adicional={adicional} />
              </>
            ))} */}

            {produto.obs.length > 0 ? (
              <>
                {" "}
                <div className="infos-produto">
                  {" "}
                  <p className="obs-opcional mb-0">
                    - {PrimeiraLetraMaiuscula(produto.obs)}
                  </p>{" "}
                </div>
              </>
            ) : null}
          </div>

          <div className="detalhes-produto-edit">
            {/* <i className="fas fa-trash" onClick={() => remove(index)}></i> */}
          </div>
        </div>
      </div>
    </>
  );
}
