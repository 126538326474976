import { ComponenteItemProdutoSabor } from "./item";

export function ComponentSaborProduto({
  Sabor,
  add,
  selecionado,
  max,
  ordem,
  remove,
}) {
  return (
    <>
      {Sabor.map((adicional, index) => (
        <>
          <ComponenteItemProdutoSabor
            adicional={adicional}
            add={add}
            selecionado={selecionado}
            max={max}
            ordem={ordem}
            index={index}
            remove={remove}
          />
        </>
      ))}
    </>
  );
}
