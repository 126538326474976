import Skeleton from "react-loading-skeleton";
import {
  Busca,
  FormatoBR,
  PrimeiraLetraMaiuscula,
  RetornaCategoria,
  RetornaProduto,
  Url,
  ValorProduto,
} from "../../controller/function";
import { useState, useEffect } from "react";

export function ComponenteProdutoPrincipal({ categoria, empresa, total }) {
  const [Produto, setProduto] = useState([]);

  useEffect(() => {
    async function fetchEmpresa() {
      // console.log(categoria);
      let value = await RetornaProduto(categoria);
      if (value != null) {
        setTimeout(() => {
          setProduto(value);
        }, 1000);
      }
    }
    fetchEmpresa();
  }, [categoria]);

  const idProduto = empresa.user_id + "&&-&&" + empresa.nome_empresa + "&&-&&";

  function Crip(valor) {
    valor = btoa(valor);
    return valor.slice(0, -2);
  }
  function AbrirProduto(codigo) {
    window.location.href = Url() + "/produto/" + codigo;
  }

  function LoadSkeleton() {
    const elementos = [];

    // Loop para criar elementos JSX
    for (let i = 0; i < total - 1; i++) {
      // Adiciona elementos JSX ao array
      elementos.push(
        <div key={i} className="card mb-2 item-cardapio">
          <div className="d-flex">
            <div
              className="container-img-produto"
              style={{ backgroundColor: "transparent" }}
            >
              <Skeleton
                containerClassName="ontainer-img-produto"
                height={"100%"}
                width={"100%"}
                className="skeleton-item"
                style={{ maxWidth: "650px" }}
              />
            </div>
            <div className="infos-produto">
              <Skeleton
                height={"20px"}
                width={"100%"}
                className="skeleton-item"
                style={{ maxWidth: "630px" }}
              />

              <Skeleton
                height={"20px"}
                width={"100%"}
                className="skeleton-item"
              />

              <Skeleton
                height={"20px"}
                width={"50vw"}
                className="skeleton-item"
                style={{ maxWidth: "620px" }}
              />
            </div>
          </div>
        </div>
      );
    }

    // Retorna os elementos JSX
    return <div>{elementos}</div>;
  }

  return (
    <>
      {Produto.length != 0 ? (
        <>
          {Produto.map((produto, index) => (
            <div
              className="card mb-2 item-cardapio"
              key={index}
              id={Crip(idProduto + produto.id + "&&-&&" + produto.nome_item)}
              onClick={() =>
                AbrirProduto(
                  Crip(idProduto + produto.id + "&&-&&" + produto.nome_item)
                )
              }
            >
              <div className="d-flex">
                <div
                  className="container-img-produto"
                  style={{
                    backgroundImage:
                      produto.img_ifood != ""
                        ? `url('${produto.img_ifood}')`
                        : `url('https://fotos.goopedir.com/fotos/${btoa(
                            produto.id
                          )}')`,
                    backgroundSize: "cover",
                  }}
                >
                  {/* <span className="gift-icon">&#x1F381;</span> */}

                  {produto.fidelidade_valor > 0 ? (
                    <>
                      {" "}
                      <div className="gift-icon">
                        <img
                          src="/gift.png"
                          alt="fidelidade"
                          style={{ width: "26px", height: "26px" }}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="infos-produto">
                  <p className="name">
                    <b>{PrimeiraLetraMaiuscula(produto.nome_item)}</b>
                  </p>
                  <p className="description">
                    {PrimeiraLetraMaiuscula(produto.descricao_item)}
                  </p>
                  <p className="price">
                    <b>{ValorProduto(produto)}</b>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <LoadSkeleton />
        </>
      )}
    </>
  );
}
