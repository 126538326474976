import {
  FormatoBR,
  PrimeiraLetraMaiuscula,
} from "../../../../controller/function";

export function ComponenteCarrinhoProduto({ product, remove, index }) {
  const Adicionais = (adicional) => {
    var filtered = adicional.adicional.selecionado.filter(function (item) {
      return item !== null && typeof item === "object";
    });

    filtered = filtered.filter(
      (item) => !(typeof item === "object" && Object.keys(item).length === 0)
    );

    // console.log(filtered)

    return (
      <>
        {filtered.map((adicional, index) => (
          <>
            <div className="infos-produto" key={"adc" + index}>
              <p className="name-opcional mb-0">
                {adicional.qtd}x {PrimeiraLetraMaiuscula(adicional.name)}
              </p>
              {adicional.value > 0 ? (
                <p className="price-opcional mb-0">
                  + {FormatoBR(adicional.value)}
                </p>
              ) : null}
            </div>
          </>
        ))}
      </>
    );
  };

  const Pizza = (pizza, outros) => {
    return (
      <>
        <div className="infos-produto" key={"adc" + index}>
          <p className="name-opcional mb-0">
            {`1/${pizza.outros.pizza.quantidade} ${PrimeiraLetraMaiuscula(
              pizza.pizza.tipo
            )} ${PrimeiraLetraMaiuscula(pizza.pizza.nome)}`}
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="card mb-2 pr-0">
        <div className="container-detalhes">
          <div className="detalhes-produto">
            <div className="infos-produto">
              <p className="name">
                <b>
                  {product.quantidade}x{" "}
                  {PrimeiraLetraMaiuscula(product.produto.nome_item)}
                </b>
              </p>
              {product.total > 0 ? (
                <p className="price">
                  <b>{FormatoBR(product.total)}</b>
                </p>
              ) : null}
            </div>

            {product.pizza?.sabores.map((sabores, index) => (
              <>
                <Pizza pizza={sabores} outros={product} />
              </>
            ))}

            {product.adicional.map((adicional, index) => (
              <>
                <Adicionais adicional={adicional} />
              </>
            ))}

            {product.observacao.length > 0 ? (
              <div className="infos-produto">
                {" "}
                <p className="obs-opcional mb-0">- {product.observacao}</p>{" "}
              </div>
            ) : null}
          </div>

          <div className="detalhes-produto-edit">
            <i className="fas fa-trash" onClick={() => remove(index)}></i>
          </div>
        </div>
      </div>
    </>
  );
}
