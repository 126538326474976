import { useState } from "react"
import { APIPAINEL, Grava } from "../../../controller/function";


export function PainelLogin() {
    const [Email, setEmail] = useState('')
    const [Senha, setSenha] = useState('')



    function Acessar() {

        APIPAINEL.get('/token', {
            headers: {
                'user': btoa(btoa(btoa(Email))),
                'password': btoa(btoa(btoa(Senha)))
            }
        }).then(function (response) {
            console.log(response.data);
            Grava('painel_token', (response.data.token))
            Grava('painel_user', (response.data.user))
        }).catch(function (err) {

        }
        )

    }
    return (<>

        <section class="login">

            <div class="card card-login">

                <img src="../img/goopedir-logo.png" width="100" />

                <div class="form-group mb-2">
                    <span class="icon-form">
                        <i class="fas fa-envelope"></i>
                    </span>
                    <input type="email" class="form-control" placeholder="E-mail" value={Email} onChange={(e) => (setEmail(e.target.value))} />
                </div>

                <div class="form-group mb-3">
                    <span class="icon-form">
                        <i class="fas fa-lock"></i>
                    </span>
                    <input type="password" class="form-control" placeholder="Senha" value={Senha} onChange={(e) => (setSenha(e.target.value))} />
                </div>

                {/* <a href="#" class="link">
                    Esqueceu sua senha?
                </a> */}

                <a class="btn btn-yellow btn-login mt-4" onClick={Acessar}>
                    Fazer Login
                </a>

            </div>

        </section>

    </>)
}