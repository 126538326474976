import { ShowModal } from "../../../../componentes/modal";
import { APIGOOPEDIR, Notificacao } from "../../../../controller/function";

export function ModalCupom({
  CloseButton,
  setCupomDesconto,
  CupomDesconto,
  Empresa,
  setDadosCupom,
}) {
  function ValidarCupom() {
    APIGOOPEDIR.post(`api/pedido/validar/cupom`, {
      user: Empresa.user_id,
      cupom: CupomDesconto,
    }).then(function (response) {
      const data = response.data;
      data.cupom = CupomDesconto;

      if (data.status) {
        setDadosCupom(data);
        console.log(data);
        Notificacao("Cupom aplicado com sucesso!");
        CloseButton();
      } else {
        setDadosCupom({ status: false });
        // Notificação('')
        Notificacao("Cupom inválido!");
      }
    });
  }

  return (
    <>
      <ShowModal>
        <header className="width-fix mt-3">
          <div className="card">
            <div className="d-flex">
              <div className="container-voltar" onClick={CloseButton}>
                <i className="fas fa-arrow-left"></i>
              </div>
              <div className="infos text-center">
                <h1 className="mb-0">
                  <b>Cupom de Desconto</b>
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="container-group mt-2">
          <p className="title-categoria mb-0">
            <b>Cupom</b>
            {/* <span className="badge">Obrigatório</span> */}
          </p>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Informe o cupom"
            value={CupomDesconto}
            onChange={(e) => setCupomDesconto(e.target.value)}
          />
        </div>

        <div className="container-group mt-4">
          <div className="btn btn-yellow btn-full-size" onClick={ValidarCupom}>
            Validar Cupom
          </div>
        </div>
      </ShowModal>
    </>
  );
}
