import React, { useState, useRef, useEffect } from 'react';
import { APIPAINEL, FormatoBR, PrimeiraLetraMaiuscula } from '../../../controller/function';


export function PainelPedidos() {
    const [Pedidos, setPedidos] = useState([]);

    useEffect(() => {
        APIPAINEL.get('/pedidos').then(function (response) {
            setPedidos(response.data);
            console.log(response.data);
        });
    }, []);


    return (<>




        <div class="conteudo-inner">
            <div class="container">
                <div class="row">

                    <div class="col-12">
                        <div class="menus-pedido">

                            <a href="#" class="btn btn-white btn-sm active">
                                <i class="far fa-dot-circle"></i> Pendentes
                                <span class="badge-total-pedidos">2</span>
                            </a>

                            <a href="#" class="btn btn-white btn-sm">
                                <i class="far fa-thumbs-up"></i> Aceito
                                <span class="badge-total-pedidos">1</span>
                            </a>

                            <a href="#" class="btn btn-white btn-sm">
                                <i class="far fa-clock"></i> Em preparo
                                <span class="badge-total-pedidos">1</span>
                            </a>

                            <a href="#" class="btn btn-white btn-sm">
                                <i class="fas fa-motorcycle"></i> Em entrega
                            </a>

                            <a href="#" class="btn btn-white btn-sm">
                                <i class="far fa-check-circle"></i> Concluído
                            </a>

                        </div>
                    </div>

                    <div class="col-12">
                        <div class="row lista-pedidos mt-5">




                            {Pedidos.map((pedidos, index) => (

                                <div class="col-3" key={index}>
                                    <div class="card card-pedido">

                                        <div class="card-pedido-header">
                                            <div class="dropdown">
                                                <button class="btn btn-white btn-sm dropdown-toggle active" type="button" data-bs-toggle="dropdown" aria-expanded="false" id={`menu${index}`}>
                                                    Pendente
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby={`menu${index}`}>
                                                    <a class="dropdown-item" href="#">Mover para <b>Aceito</b> <i class="far fa-thumbs-up"></i></a>
                                                    <a class="dropdown-item" href="#">Mover para <b>Em preparo</b> <i class="far fa-clock"></i></a>
                                                    <a class="dropdown-item" href="#">Mover para <b>Em entrega</b> <i class="fas fa-motorcycle"></i></a>
                                                    <a class="dropdown-item" href="#">Mover para <b>Concluído</b> <i class="far fa-check-circle"></i></a>
                                                    <a class="dropdown-item" href="#">Recusar Pedido <i class="far fa-times-circle"></i></a>
                                                </div>
                                            </div>
                                            <p class="numero-pedido mt-2">#{pedidos.codigo_pedido}</p>
                                        </div>

                                        <div class="card-content" data-bs-toggle="modal" data-bs-target="#modalDetalhes">

                                            <div class="card-pedido-body mt-3">
                                                <p class="info-pedido">
                                                    <i class="fas fa-user"></i> {PrimeiraLetraMaiuscula(pedidos.nome)}
                                                </p>
                                                <p class="info-pedido">


                                                    {pedidos.rua == '' ? <><i class="fas fa-building"></i> Retirada</> : <><i class="fas fa-motorcycle"></i> Entrega</>}

                                                    {/* <i class="fa-regular fa-building"></i> */}
                                                </p>
                                                <p class="info-pedido">
                                                    <i class="fas fa-coins"></i> {PrimeiraLetraMaiuscula(pedidos.forma_pagamento)}
                                                    {pedidos.valor_troco > 0 ? <span>Troco para {FormatoBR(pedidos.valor_troco)}</span> : null}

                                                </p>
                                            </div>

                                            <div class="separate"></div>

                                            <div class="card-pedido-footer">
                                                <p class="horario-pedido">Recebido há 33 minutos</p>
                                                <p class="total-pedido"><b>R$ 143,50</b></p>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            ))}

                        </div>
                    </div>

                </div>
            </div>
        </div>



    </>)
}