export function ValidarNome(nome) {
  // Verifica se a string contém pelo menos um espaço em branco
  if (nome && nome.includes(" ")) {
    return true; // Se há um espaço em branco, então há um nome e um sobrenome
  } else {
    return false; // Caso contrário, não há um nome e um sobrenome
  }
}

export function ValidarCelular(numero) {
  // Expressão regular para validar números de celular
  const regex = /^[0-9]{11}$/; // Formato: 10 dígitos

  // Verifica se o número corresponde à expressão regular
  return regex.test(numero);
}

export function ValidarEndereco(Delivery, Cliente) {
  if (Delivery) {
    if (Cliente.rua.length == 0) {
      return { status: false, mensagem: "Rua não informada!", endereco: {} };
    }
    if (Cliente.bairro.length == 0) {
      return { status: false, mensagem: "Bairro não informado!", endereco: {} };
    }
    if (Cliente.cidade.length == 0) {
      return { status: false, mensagem: "Cidade não informada!", endereco: {} };
    }
    if (Cliente.numero.length == 0) {
      return { status: false, mensagem: "Número não informado!", endereco: {} };
    }
    if (Cliente.complemento.length == 0) {
      return {
        status: false,
        mensagem: "Complemento não informado!",
        endereco: {},
      };
    }

    return {
      status: true,
      mensagem: "",
      endereco: {
        rua: Cliente.rua,
        bairro: Cliente.bairro,
        cidade: Cliente.cidade,
        uf: Cliente.uf,
        numero: Cliente.numero,
        complemento: Cliente.complemento,
      },
    };
  } else {
    return { status: true, mensagem: "", endereco: {} };
  }
}

export function validarCPF(cpf) {
  // Remove caracteres não numéricos
  cpf = cpf.replace(/[^\d]/g, "");

  // Verifica se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Verifica se todos os dígitos são iguais, o que é inválido para CPF
  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  // Calcula o primeiro dígito verificador
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let digit1 = sum % 11;
  digit1 = digit1 < 2 ? 0 : 11 - digit1;

  // Verifica o primeiro dígito verificador
  if (parseInt(cpf.charAt(9)) !== digit1) {
    return false;
  }

  // Calcula o segundo dígito verificador
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  let digit2 = sum % 11;
  digit2 = digit2 < 2 ? 0 : 11 - digit2;

  // Verifica o segundo dígito verificador
  if (parseInt(cpf.charAt(10)) !== digit2) {
    return false;
  }

  // CPF válido
  return true;
}

export function formatDate(dateString) {
  // Verifica se a data está no formato "26022000"
  if (/^\d{8}$/.test(dateString)) {
    // Extrai o dia, mês e ano da string
    const day = dateString.substring(0, 2);
    const month = dateString.substring(2, 4);
    const year = dateString.substring(4, 8);

    // Retorna a data no formato "DD/MM/YYYY"
    return `${day}/${month}/${year}`;
  }

  // Se a data já estiver no formato "DD/MM/YYYY", retorna a string original
  return dateString;
}

export function maiorDe18Anos(dataNascimento) {
  // Divide a string da data de nascimento em dia, mês e ano
  const partes = formatDate(dataNascimento).split("/");
  const dia = parseInt(partes[0], 10);
  const mes = parseInt(partes[1], 10) - 1; // Mês é zero-indexed em JavaScript
  const ano = parseInt(partes[2], 10);

  // Cria um objeto Date com a data de nascimento
  const dataNascimentoObj = new Date(ano, mes, dia);

  // Obtém a data atual
  const dataAtual = new Date();

  // Define a data de corte para 18 anos atrás
  const dataCorte = new Date();
  dataCorte.setFullYear(dataCorte.getFullYear() - 18);

  // Verifica se a data de nascimento é anterior à data de corte (18 anos atrás)
  return dataNascimentoObj <= dataCorte;
}

export function ValidarCliente(Fidelidade, Cliente) {
  if (!ValidarCelular(Cliente.celular)) {
    return { status: false, mensagem: "Celular inválido!", cliente: {} };
  }

  if (!ValidarNome(Cliente.nome)) {
    return {
      status: false,
      mensagem: "Deve-se informar um nome e sobrenome!",
      cliente: {},
    };
  }

  if (Fidelidade) {
    if (!validarCPF(Cliente.cpf)) {
      return {
        status: false,
        mensagem: "Deve-se informar o cpf para o programa de fidelidade!",
        cliente: {},
      };
    }
    if (!maiorDe18Anos(Cliente.nascimento)) {
      return {
        status: false,
        mensagem: "Podem participar, apenas maiores de 18 anos!",
        cliente: {},
      };
    }
  }
  return {
    status: true,
    mensagem: "",
    cliente: {
      cpf: Cliente.cpf,
      nascimento: formatDate(Cliente.nascimento),
      nome: Cliente.nome,
      celular: Cliente.celular,
    },
  };
}

export function RetornoProduto(produto) {
  let dados = [];
  for (var i = 0; i < produto.length; i++) {
    let PRODUTO = produto[i];
    let EXTRA = produto[i].adicional;
    let SABOR = produto[i].pizza.sabores;
    let product = {
      id: PRODUTO.produto.id,
      nome: PRODUTO.produto.nome_item,
      valor: PRODUTO.total,
      quantidade: PRODUTO.quantidade,
      observacao: PRODUTO.observacao,
    };
    let extraProduct = [];

    for (var k = 0; k < EXTRA.length; k++) {
      let extraSelecionado = EXTRA[k].selecionado;

      for (var j = 0; j < extraSelecionado.length; j++) {
        try {
          if (extraSelecionado[j].qtd > 0) {
            extraProduct.push({
              categoria: EXTRA[k].name,
              nome: extraSelecionado[j].name,
              quantidade: extraSelecionado[j].qtd,
              valor: extraSelecionado[j].value,
              id: extraSelecionado[j].id,
            });
          }
        } catch (error) {}
      }
    }
    for (var k = 0; k < SABOR.length; k++) {
      let saborSelecionado = SABOR[k];

      extraProduct.push({
        categoria: saborSelecionado.tipo,
        nome: saborSelecionado.nome,
        quantidade: 1,
        valor: saborSelecionado.value,
        id: saborSelecionado.sabor,
      });
    }

    product.extra = extraProduct;
    dados.push(product);
  }

  return dados;
}
