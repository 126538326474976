export function ComponentLoading() {
  return (
    <>
      <div className="center-div">
        <div class="loader-container">
          <div class="logo">
            <img src="/logo.png" alt="Logo" />
          </div>
          <div class="spinner">
            <div class="dot dot1"></div>
            <div class="dot dot2"></div>
            <div class="dot dot3"></div>
            <div class="dot dot4"></div>
            <div class="dot dot5"></div>
            <div class="dot dot6"></div>
            <div class="dot dot7"></div>
            <div class="dot dot8"></div>
          </div>
        </div>
      </div>
    </>
  );
}
