import { useState, useEffect } from "react";
import { APIGOOPEDIR } from "../../../controller/function";
import { ComponenteItemProduto } from "../item";

export function ComponentAdicionalProduto({
  codigo,
  add,
  selecionado,
  produto,
  nome,
}) {
  const [Adicionais, setAdicionais] = useState([]);

  useEffect(() => {
    APIGOOPEDIR.get(`api/adicional/categoria/${produto}/${nome}`).then(
      function (response) {
        var dados = response.data;
        if (dados != null && dados.length > 0) {
          setAdicionais(dados);
        }
      }
    );
  }, []);

  return (
    <>
      {Adicionais.map((adicional, index) => (
        <>
          <ComponenteItemProduto
            adicional={adicional}
            add={add}
            selecionado={selecionado}
            index={index}
            nomeCategoria={nome}
          />
        </>
      ))}
    </>
  );
}
