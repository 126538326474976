import { useParams } from "react-router-dom";
import { ComponenteEmpresa } from "../../componentes/empresa";
import { useEffect, useState } from "react";
import { ComponentButton } from "../../componentes/buttomBottom";
import { StatusPedido } from "./componente/status";
import { CardPedido } from "./componente/pedido";
import { APIGOOPEDIR, Busca, Url } from "../../controller/function";
import { getData, getDataMenos30 } from "./controller/function";
import { ComponentLoading } from "../../componentes/loading";
import { DadosPedidos } from "./componente/dados";

export function PagePedido() {
  const { empresa } = useParams();
  const [Empresa, setEmpresa] = useState(null);
  const [Categoria, setCategoria] = useState([]);
  const [Pedido, setPedido] = useState([]);
  const [Cliente, setCliente] = useState({
    celular: "",
    nome: "",
    cpf: "",
    nascimento: "",
    rua: "",
    ruaa: "",
    bairro: "",
    cidade: "",
    uf: "",
    complemento: "",
    numero: "",
    lat: 0,
    lng: 0,
  });

  const [DataInicial, setDataInicial] = useState("");
  const [DataFinal, setDataFinal] = useState("");

  useEffect(() => {
    let Client = JSON.parse(Busca("client"));
    setDataInicial(getDataMenos30());
    setDataFinal(getData());

    if (Client) {
      setCliente(Client);
    }
  }, []);

  useEffect(() => {
    let empresa = Empresa?.user_id ? true : false;
    if (empresa) {
      ConsultarPedidos();
    }
  }, [Empresa]);

  function ConsultarPedidos() {
    if (Cliente.celular.length == 11) {
      APIGOOPEDIR.post(
        `api/pedidos/cliente/${Empresa.user_id}/${Cliente.celular}`,
        {
          inicial: DataInicial,
          final: DataFinal,
        }
      ).then((response) => {
        setPedido(response.data);
      });
    }
  }

  return (
    <>
      <ComponenteEmpresa
        setempresa={setEmpresa}
        setcategoria={setCategoria}
        display={"none"}
      ></ComponenteEmpresa>
      {Empresa ? (
        <>
          <DadosPedidos
            DataInicial={DataInicial}
            setDataInicial={setDataInicial}
            DataFinal={DataFinal}
            setDataFinal={setDataFinal}
            ConsultarPedidos={ConsultarPedidos}
            Pedido={Pedido}
            Cliente={Cliente}
          />

          <ComponentButton />
        </>
      ) : (
        <>
          <ComponentLoading />
        </>
      )}
    </>
  );
}
