import {
  PrimeiraLetraMaiuscula,
  RetornaIconePagamento,
  identificarMeioPagamento,
} from "../../../../controller/function";

export function ComponenteFormaPagamento({
  Descricao,
  index,
  Pix,
  PagamentoSelecionado,
  setPagamentoSelecionado,
}) {
  let PgDescricao = "";

  if (Pix.length > 0) {
    PgDescricao = "Pagamento Online";
  }

  if (identificarMeioPagamento(Descricao) == 0) {
    PgDescricao = "Pagamento Presencial";
  }
  if (identificarMeioPagamento(Descricao) == 2) {
    PgDescricao = "Pagamento Presencial";
  }

  if (identificarMeioPagamento(Descricao) == 3) {
    PgDescricao = "Informar troco";
  }

  function SelecionaPagamento() {
    setPagamentoSelecionado({
      descricao: Descricao,
      pix: identificarMeioPagamento(Descricao) == 1 ? true : false,
      troco: identificarMeioPagamento(Descricao) == 3 ? true : false,
    });
  }

  return (
    <>
      <div
        className="card card-address mt-2"
        key={index}
        onClick={SelecionaPagamento}
        style={{
          backgroundColor:
            Descricao == PagamentoSelecionado.descricao
              ? "rgba(0,0,0,0.09)"
              : "",
        }}
      >
        <div className="img-icon-details">
          {RetornaIconePagamento(Descricao)}
        </div>
        <div className="infos">
          <p className="name mb-0">
            <b>{PrimeiraLetraMaiuscula(Descricao)}</b>
          </p>

          <span className="text mb-0">{PgDescricao}</span>
        </div>
      </div>
    </>
  );
}
