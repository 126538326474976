import { useState } from "react";
import { ComponenteTermo } from "./termo";
import { PagePrivacidade } from "./privacidade";

export function TermoPrivacidade() {
  const [select, setselect] = useState(1);
  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  return (
    <>
      <div style={{ height: "200px" }}>
        <div style={{ marginTop: "50px" }}>
          <h1
            style={{
              fontSize: isMobile() ? "1rem" : "2rem",
              textAlign: "center",
            }}
          >
            <span
              style={{
                borderBottom: `5px solid ${
                  select == 1 ? "rgb(168, 0, 28)" : "transparent"
                }`,
                color: select == 1 ? "rgb(168, 0, 28)" : "black",
                fontSize: isMobile() ? "1rem" : "2rem",
              }}
              onClick={() => setselect(1)}
            >
              Termo de Uso
            </span>{" "}
            e{" "}
            <span
              style={{
                borderBottom: `5px solid ${
                  select == 2 ? "rgb(168, 0, 28)" : "transparent"
                }`,
                color: select == 2 ? "rgb(168, 0, 28)" : "black",
                fontSize: isMobile() ? "1rem" : "2rem",
              }}
              onClick={() => setselect(2)}
            >
              Política de Privacidade
            </span>
          </h1>
        </div>
      </div>
      <div
        style={{
          marginLeft: isMobile() ? "0" : "30%",
          width: isMobile() ? "100%" : "40%",
          backgroundColor: "white",
          //   height: "100vh",
          padding: "10px",
          overflowX: "auto",
        }}
      >
        {select == 1 ? <ComponenteTermo /> : <PagePrivacidade />}
      </div>
    </>
  );
}
