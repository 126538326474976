import { ComponentSaborProduto } from "../../../componentes/produto/sabor";
import { PrimeiraLetraMaiuscula } from "../../../controller/function";

export function Sabor({
  Pizza,
  AdicionaisSelecionados,
  AddAdicional,
  RemoveSabor,
}) {
  return (
    <>
      {Pizza.length > 0
        ? ComponenteSabor(
            Pizza,
            AdicionaisSelecionados,
            AddAdicional,
            RemoveSabor
          )
        : null}
      {/* {Pizza[0].qtd_sabor &&
        Pizza[0].sabores.map((sabor, index) => <div key={index}>{index}</div>)} */}
    </>
  );
}
function ComponenteSabor(
  Pizza,
  AdicionaisSelecionados,
  AddAdicional,
  RemoveSabor
) {
  const sabores = [];
  for (let i = 0; i < Pizza[0].qtd_sabor; i++) {
    sabores.push(
      <div className="container-group mb-5" key={i} id={"sabor" + (i + 1)}>
        <span className="badge">Obrigatório</span>
        <p className="title-categoria mb-0">
          <b>{PrimeiraLetraMaiuscula(`Escolha o ${CaptionSabor(i)} sabor `)}</b>
        </p>
        <span className="sub-title-categoria">Escolha o sabor desejado!</span>

        <ComponentSaborProduto
          Sabor={Pizza}
          ordem={i}
          max={Pizza[0].qtd_sabor}
          // min={adicional.minimo}
          // max={adicional.amount}
          selecionado={AdicionaisSelecionados}
          add={AddAdicional}
          remove={RemoveSabor}
        />
      </div>
      // <div key={i}>{i}</div>
    );
  }
  return <>{sabores}</>;
}

function CaptionSabor(index) {
  switch (index) {
    case 0:
      return "Primeiro";
      break;
    case 1:
      return "Segundo";
      break;
    case 2:
      return "Terceiro";
      break;
    case 3:
      return "Quarto";
      break;
    case 4:
      return "Quinto";
      break;
    case 5:
      return "Sexto";
      break;
    default:
      return "";
      break;
  }
}
