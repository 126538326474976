export function DetalheCard({ Descricao, Valor }) {
  return (
    <>
      <div className="detalhes-produto">
        <div className="infos-produto">
          <p className="name mb-0">{Descricao}</p>
          <p className="price mb-0">
            <b>{Valor}</b>
          </p>
        </div>
      </div>
    </>
  );
}
