import { Url } from "../../../../controller/function";
import { CardPedido } from "../pedido";

export function DadosPedidos({
  DataInicial,
  DataFinal,
  ConsultarPedidos,
  Pedido,
  Cliente,
  setDataInicial,
  setDataFinal,
}) {
  return (
    <>
      <body>
        <div class="bg-top pedido"></div>

        <header class="width-fix mt-3">
          <div class="card">
            <div class="d-flex">
              <a href={Url()} className="container-voltar">
                <i class="fas fa-arrow-left"></i>
              </a>
              <div class="infos text-center">
                <h1 class="mb-0">
                  <b>Acompanhar pedido</b>
                </h1>
              </div>
            </div>
          </div>
        </header>
        <section class="pedido width-fix mt-4 ">
          <div class="card card-status-pedido mb-4">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <div class="row">
                  <div class="form-group">
                    <p
                      class="title-categoria mb-0"
                      style={{ fontSize: "0.85rem" }}
                    >
                      <b>Data início:</b>
                    </p>
                    <input
                      type="date"
                      class="form-control"
                      value={DataInicial}
                      onChange={(e) => setDataInicial(e.target.value)}
                    />
                  </div>

                  <div class="form-group mt-2">
                    <p
                      class="title-categoria mb-0"
                      style={{ fontSize: "0.85rem" }}
                    >
                      <b>Data fim:</b>
                    </p>
                    <input
                      type="date"
                      class="form-control"
                      value={DataFinal}
                      onChange={(e) => setDataFinal(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                className="btn btn-yellow btn-full-size mt-2"
                onClick={ConsultarPedidos}
              >
                Buscar
              </div>
            </div>
          </div>
        </section>

        <section
          class={`pedido width-fix mt-4 ${Pedido.length == 0 ? "" : "hidden"} `}
        >
          <div class="card card-status-pedido">
            <div class="img-icon-details">
              <i class="fas fa-utensils"></i>
            </div>
            <div class="infos">
              <p class="name mb-1">
                <b>Nenhum pedido realizado</b>
              </p>
              <span class="text mb-0">
                Faça um pedido e o status dele aparecerá aqui
              </span>
            </div>
          </div>
        </section>

        <section class="pedido width-fix mt-4">
          {Pedido.map((pedido, index) => (
            <CardPedido pedido={pedido} />
          ))}
        </section>
      </body>
    </>
  );
}
