import {
  FormatoBR,
  Notificacao,
  PrimeiraLetraMaiuscula,
} from "../../../controller/function";
import { useState, useEffect } from "react";

export function ComponenteItemProduto({
  adicional,
  add,
  selecionado,
  key,
  index,
  nomeCategoria,
}) {
  const [Select, setSelect] = useState(false);

  function MarcaDesMarca(nome, valor, id, categoria) {
    // console.log(nome);
    // console.log(valor);
    // console.log(id);
    // console.log(categoria);

    if (Select) {
      setSelect(!Select);
      add({
        id: parseInt(id),
        name: nome,
        value: parseFloat(valor),
        id_categoria: parseInt(categoria),
        status: !Select,
      });
      return true;
    }

    // console.log(selecionado[categoria]);
    try {
      if (selecionado[categoria].qtd == selecionado[categoria].max) {
        Notificacao("Deve-se desmarcar um(a) " + nomeCategoria);
      } else {
        add({
          id: parseInt(id),
          name: nome,
          value: parseFloat(valor),
          id_categoria: parseInt(categoria),
          status: !Select,
        });
        setSelect(!Select);
      }
    } catch (error) {
      add({
        id: parseInt(id),
        name: nome,
        value: parseFloat(valor),
        id_categoria: parseInt(categoria),
        status: !Select,
      });
      setSelect(!Select);
    }
  }

  return (
    <>
      <div className="card card-opcionais mt-2" key={"adicionais" + index}>
        <div className="infos-produto-opcional">
          <p className="name mb-0">
            <b>{PrimeiraLetraMaiuscula(adicional.nome_adicional)}</b>
          </p>
          <p className="price mb-0">
            <b>
              {adicional.valor_adicional > 0
                ? `+ ${FormatoBR(adicional.valor_adicional)}`
                : ""}
            </b>
          </p>
        </div>

        <div className="checks">
          <label className="container-check">
            <input
              type="checkbox"
              onClick={() =>
                MarcaDesMarca(
                  PrimeiraLetraMaiuscula(adicional.nome_adicional),
                  adicional.valor_adicional,
                  adicional.id_adicionais,
                  adicional.id_adicionais_cat
                )
              }
              checked={Select}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
    </>
  );
}
