import { useState, useEffect } from "react";
import {
  FormatoBR,
  Notificacao,
  PrimeiraLetraMaiuscula,
} from "../../../../controller/function";

export function ComponenteItemProdutoSabor({
  adicional,
  add,
  selecionado,
  index,
  max,
  ordem,
  remove,
}) {
  const [Select, setSelect] = useState(false);

  function MarcaDesMarca(nome, valor, id, tipo, tipo_valor, max, sabor) {
    if (Select) {
      setSelect(!Select);
      remove({
        id: parseInt(id),
        name: nome,
        value: parseFloat(valor),
        tipo: tipo,
        tipo_valor: tipo_valor,
        status: !Select,
        max: max,
        sabor: sabor,
      });
      return true;
    }

    let SaborSelecionado = selecionado["sabor" + ordem];
    var podeSelecionar = false;

    if (SaborSelecionado == undefined) {
      podeSelecionar = true;
    } else {
      if (SaborSelecionado?.nome == nome) {
      } else {
        Notificacao("Deve-se desmarcar um sabor");
      }
    }
    // console.log(selecionado["sabor" + ordem]);
    if (podeSelecionar) {
      if (SaborSelecionado?.nome == nome) {
      } else {
        add({
          id: parseInt(id),
          name: nome,
          value: parseFloat(valor),
          tipo: tipo,
          tipo_valor: tipo_valor,
          status: !Select,
          max: max,
          sabor: sabor,
        });
        setSelect(!Select);
      }
    }
    // if (selecionado[categoria].qtd == selecionado[categoria].max) {
    // } else {

    // }
  }

  return (
    <>
      <div className="card card-opcionais mt-2" key={ordem + "sabores" + index}>
        <div className="infos-produto-opcional">
          <div>
            <p className="name mb-0">
              <b>
                {"1/" + max + " " + PrimeiraLetraMaiuscula(adicional.sabor)}
              </b>
            </p>
            <p className="name mb-0">
              {PrimeiraLetraMaiuscula(adicional.descricao)}
            </p>
            <p className="name mb-0">
              {" "}
              <b>
                {adicional.valor > 0 ? `${FormatoBR(adicional.valor)}` : ""}
              </b>
            </p>
          </div>
        </div>

        <div className="checks">
          <label className="container-check">
            <input
              type="checkbox"
              onClick={() =>
                MarcaDesMarca(
                  PrimeiraLetraMaiuscula(adicional.sabor),
                  adicional.valor,
                  ordem,
                  adicional.tipo,
                  adicional.tipo_valor,
                  max,
                  adicional.id
                )
              }
              checked={Select}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
    </>
  );
}
